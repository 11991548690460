.destination{
    margin: 4rem 6rem;
}

.destination h1{
    font-size: 3rem;
}
.first-des{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.first-des-rev{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}
.des-text{
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}
.des-text h2{
    padding-bottom: 1rem;
}

.image{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.image img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image img:nth-child(2){
    position: absolute;
    top: -15%;
    right: 0; 
}

.primaryText {
    color: #1f3e72;
    font-weight: bold;
    font-size: 2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .secondaryText {
    color: rgb(46, 155, 155);
    font-size:3.5rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .orangeText {
    color: cornflowerblue;
    font-size: 1rem;
    font-weight: 600;
    
  }

